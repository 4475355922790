
/* eslint-disable max-len */
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CaptureUpdate } from '../models/capture-update';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRHubService {
  onUpdateReceived$ = new Subject<CaptureUpdate>();
  onImageReceived$ = new Subject<string>();
  onNewJobCreated$ = new Subject<string>();

  private hubUrl = 'SERVER_URL/signalr';
  private connection: SignalR.Hub.Connection;
  private photoReviewHubProxy: any;

  private isReconnecting = false; // Flag to control reconnection

  // used to update the connection icon when the signalR connection state changes
  private isSignalRConnectedSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private zone: NgZone,
    private localStorageService: LocalStorageService
  ) {    
    this.localStorageService.siteUrl$.subscribe(async value => {
     if (value) {
        this.hubUrl = value;
        this.initializeSignalRConnection(this.hubUrl );
      }
    });
  }

  isSignalRConnected$(){
    return this.isSignalRConnectedSubject.asObservable();
  }

  private initializeSignalRConnection(url: string) {
    console.log('signal r url', url);    

    // Create the connection to the hub.
    this.connection = $.hubConnection(url);

    if (!environment.production) {
      this.connection.logging = true;
    }
        
    this.connection.disconnected(async () => {
      //TODO: add logging mechanism
      console.log('disconnected signal r connection');
      if (!this.isReconnecting) {
        this.zone.run(() => {
          this.isSignalRConnectedSubject.next(false);
        });
        this.isReconnecting = true;
        this.attemptReconnect(0);
      }
    });
    
    // Setting up proxy and event listenersm
    this.setupHubProxy();
    
    this.startConnection();
  }
  
  private async attemptReconnect(retryCount: number) {
    const retryDelays = [0, 5000, 10000]; // Initial delays for the first few retries
    const maxInitialRetries = retryDelays.length;
    const continuousRetryDelay = 30000; // 30 seconds for continuous retries
    
    // Check if initial retries are exceeded
    if (retryCount < maxInitialRetries) {
        setTimeout(() => {
            this.reconnect(retryCount);
        }, retryDelays[retryCount]);
    } else {
        // After initial retries, show a persistent attempt message
        setTimeout(() => {
            this.reconnect(retryCount);
        }, continuousRetryDelay); // Continue retrying every 30 seconds
    }

    console.log(`SignalR connection stopped. Attempting to reconnect after ${(retryCount < maxInitialRetries ? retryDelays[retryCount] : continuousRetryDelay)} ms...`);
  }

  private reconnect(retryCount: number) {
      this.connection.start().done(() => {
          console.log("Re-established SignalR connection successfully on attempt " + (retryCount + 1));
          this.zone.run(() => {
            this.isSignalRConnectedSubject.next(true);
          });
          this.isReconnecting = false; // Reset reconnection flag
      }).catch((error) => {
        //TODO: add logging mechanism
          console.log("SignalR reconnection attempt " + (retryCount + 1) + " failed.", error);
          this.attemptReconnect(retryCount + 1); // Increment retry count and try again
      });
  }

  private setupHubProxy() {
    this.photoReviewHubProxy = this.connection.createHubProxy('PhotoReviewHub');
    this.photoReviewHubProxy.on('updateReceived', (id: string, status: number, newNote: string, overlayMetadata: string, exportRotate: number, formattedNotes: string, allNotes: string) => {
        if (this.isParentJobDownloaded(id)) {
          const update: CaptureUpdate = { code: id, status, allNotes, exportRotate, formattedNotes, newNote, overlayMetadata };
          this.zone.run(() => {
            this.onUpdateReceived$.next(update);
          });
        }
    });

    this.photoReviewHubProxy.on('imageReceived', (id: string) => {
      if (this.isParentJobDownloaded(id)) {
        this.onImageReceived$.next(id);
      }
    });

    this.photoReviewHubProxy.on('jobCreated', (jobRef: string) => {
      console.log('signal r jobCreated response ' + jobRef);
    });
  }

  private startConnection() {
    this.connection.start().then(() => {
        console.log('Starting SignalR connection...');
    }).catch((error: Error) => {
        console.log('SignalR connection error: ', error);
    }).done(() => {
        console.log('SignalR connection started.');
    });
  }


  private isParentJobDownloaded(code: string): boolean {
    return this.localStorageService.storedJobs.find(jobCode => jobCode.JobFolders[0].Code === code.substring(0, jobCode.JobFolders[0].Code.length)) ? true : false;
  }
}


