import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { NamedColor } from '../lookups/types';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private toastCtrl: ToastController) { }

  async showToastMessage(message: string, color?: NamedColor, duration?: number ) {
    const toast = await this.toastCtrl.create({
      message,
      color: color?? 'dark',
      position: 'bottom',
      duration: duration?? 3000
    });
    await toast.present();
  }

  async showOkToastMessage(message: string, color?: NamedColor) {
    return await this.toastCtrl.create({
      message,
      color: color?? 'dark',
      position: 'bottom',
      buttons: ['ok']      
    });
  }

  async dismissToastMessage(){
    await this.toastCtrl.getTop().then(top => {
      if (top) {
        this.toastCtrl.dismiss();
      }
    });
  }
}
