/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { BlobFileType, CaptureEventType } from '../lookups/enums';
import { CaptureDataExifProperties } from '../models/capture-data-exif-properties';
import { CaptureMetaDataXml } from '../models/capture-metadata-xml';
import { Position } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  //creates xml blob that is sent to the server
  async createXMLBlob(currentUser, captureNotes: string, geoLocation: Position, eventType: CaptureEventType): Promise<Blob> {
    const captureMetaData: CaptureMetaDataXml = {
      EventType: eventType,
      DeviceCode: currentUser,
      Roll: -90
    };

    const captureExifProperties: CaptureDataExifProperties = {};
    await Device.getInfo().then(info => {
      captureExifProperties.Make = info.manufacturer;
      captureExifProperties.Model = info.model;
    });

    captureMetaData.EventTimestamp = geoLocation ? this.formatDate( new Date(geoLocation.timestamp)) : null;
    captureMetaData.EventUtcTimestamp = geoLocation ? new Date(geoLocation.timestamp).toISOString(): null;
    captureExifProperties.DateTaken = geoLocation ? new Date(geoLocation.timestamp).toISOString(): null;
    captureMetaData.Longitude = geoLocation ? geoLocation.coords.longitude : null;
    captureExifProperties.GPSLongitude = geoLocation ? geoLocation.coords.longitude : null;
    captureMetaData.Latitude = geoLocation ? geoLocation.coords.latitude : null;
    captureExifProperties.GPSLatitude = geoLocation ? geoLocation.coords.latitude : null;
    captureMetaData.Altitude = geoLocation ? geoLocation.coords.altitude : null;
    captureExifProperties.GPSAltitude = geoLocation ? geoLocation.coords.altitude : null;
    captureMetaData.CompassHeading = geoLocation ? geoLocation.coords.heading : null;

    captureMetaData.Notes = captureNotes;
    captureMetaData.ExifProperties = captureExifProperties;
    const xmlFile = this.createXmlFileFromObject(captureMetaData, 'CaptureMetadata');

    //xml blob
    const xmlFileBlob = new Blob([xmlFile], {type: 'text/xml'});
    return xmlFileBlob;
  }

   createFileBlobFromBase64String(base64String: string, fileType: BlobFileType): Blob {
    //image blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: fileType});
    return blob;
  }

  resizeImage(sourceImage, newWidth: number, newHeight: number) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = sourceImage;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }

  private createXmlFileFromObject(object, parentNode: string): string {
    // eslint-disable-next-line max-len
    let xmlString = `<?xml version="1.0" encoding="utf-8"?>\n<${parentNode} xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.bluesky101.com">\n`;

    xmlString += this.createXmlFilePropertiesFromObject(object);

    xmlString += `</${parentNode}>`;

    return xmlString;
  }

  private createXmlFilePropertiesFromObject(object: any, indentationLevel: number = 1): string {
    //xml default row indentation is 2 'spaces' to match xml file layout, accepted by the API
    const indent = '  ';
    //xml properties string
    let xmlString = '';
    if (object) {

      const properties = Object.entries(object);

      for (const property of properties) {
        if (typeof property[1] === 'object' && property[1]) {
          let innerEntity = `${indent.repeat(indentationLevel)}<${property[0]}>\n`;

          innerEntity += this.createXmlFilePropertiesFromObject(property[1], indentationLevel + 1);

          innerEntity += `${indent.repeat(indentationLevel)}</${property[0]}>\n`;

          xmlString += innerEntity;
        } else {
          if (property[1]) {
            xmlString += `${indent.repeat(indentationLevel)}<${property[0]}>${property[1]}</${property[0]}>\n`;
          }
        }
      }
    }

    return xmlString;
  }

  //formats date to 2021-10-26T10:51:30.377541+01:00, which is accepted in the closeoutpro API
  private formatDate(date: Date) {
    const timeDifference = this.setTimeDifferenceStringFromDate(date, ':');

    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') + 'T' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds())
      ].join(':') + '.' + date.getMilliseconds() + timeDifference
    );
  }

  //sets the difference between the local time and the utc time in form of a string
  // where hours and minutes are separated by given string e.g. +0100 or +01:00
  private setTimeDifferenceStringFromDate(date: Date, separator: string = ''): string{
    let timeDifferenceString = '+';
    const hourDifference = date.getHours()- date.getUTCHours();
    const minuteDifference = date.getMinutes() - date.getUTCMinutes();

    if (hourDifference < 0) {
      timeDifferenceString = '-';
    }

    if (minuteDifference < 0) {
      timeDifferenceString = '-';
    }

    timeDifferenceString += this.padTo2Digits(Math.abs(hourDifference)) + separator + this.padTo2Digits(Math.abs(minuteDifference));
    return timeDifferenceString;
  }

  //set the number to string with 2 digits
  private padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

}
