/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Filesystem_JobFolder_PhotosFolders, PhotoCaptureStatus, PhotoStatus, SimpleStatus, StatusIcon, StatusIconColor } from '../lookups/enums';
import { LocalFile } from '../models/local-file';
import { PhotoElement } from '../models/photo-element';
import { JobService } from './job.service';
import { Job } from '../models/job';
import { Capture } from '../models/capture';
import { Photo } from '../models/photo';
import { Subject } from 'rxjs';
import { LexiconService } from './lexicon.service';
import { PhotoLogSectionElement } from '../models/photo-log-section-element';
import { AWAITING_IMAGE_PATH, BASE64_IMAGE_PREFIX } from '../lookups/consts';
import { FullSizeCaptureElement } from '../models/full-size-capture-element';
import { Note } from '../models/note';
import { CaptureUpdate } from '../models/capture-update';
import { SignalRHubService } from './signal-r-hub.service';
import { CaptureService } from './capture.service';
import { PhotoSetElement } from '../models/photo-set-element';
import { PhotoLogSection } from '../models/photo-log-section';


@Injectable({
  providedIn: 'root',
})
export class PhotosPageService {
  currentJob: Job;
  jobStoredThumbnails: LocalFile[] = [];
  photoLogSectionElements: PhotoLogSectionElement[] = [];
  photoLogSectionElementsSubject = new Subject<PhotoLogSectionElement[]>();
  thumbnailFilesNames: string[];
  loResFilesNames: string[];

  updatedPhotoElement$ = new Subject<PhotoElement>();

  capture$ = new Subject<Capture>();

  //a total number of photo elements
  //used when the original number of photo elements is required e.g. all filters removed.
  photoElementList: PhotoElement[] = [];

  // used to complete photo if it is a series
  completePhotoSeries$ = new Subject<PhotoElement>();

  constructor(
    private localStorageService: LocalStorageService,
    private jobService: JobService,
    private captureService: CaptureService,
    private lexiconService: LexiconService,
    private signalRHubService: SignalRHubService,
    private zone: NgZone
  ) {
    this.captureService.newCapture$.subscribe(newCapture => {
      this.updatePhotoElementFromNewCapture(newCapture);
    });
    this.completePhotoSeries$.subscribe((photoElement: PhotoElement) => {
      this.updatePhotoSeries(photoElement);
    });
  }

  init() {
    this.currentJob = this.jobService.currentViewedJob;
    this.lexiconService.lexicon = this.currentJob.JobFolders[0].PhotoLogs[0].Lexicon;
    this.getStoredImages(this.currentJob.JobFolders[0].Code)
    .then(() => this.readStoredThumbnails(this.currentJob.JobFolders[0].Code))
    .then(() => {
      this.createPhotoLogSectionElements();
    });
    this.signalRHubService.onUpdateReceived$.subscribe((captureUpdate: CaptureUpdate) => {
      this.updatePhotoFromCaptureUpdate(captureUpdate);
    });
  }

  extractDate(dateString: string): number {
    const startIndex = dateString.indexOf('(');
    const endIndex = dateString.indexOf(')');
    const date: number = parseInt(dateString.substring(startIndex + 1, endIndex), 10);
    return date;
  }

  //sets the state from the photo elements
  setElementStateFromPhotoElements(photoElements: PhotoElement[]): any {
    const state =  {color: StatusIconColor.Warning, icon: StatusIcon.Awaiting, simpleStatus: SimpleStatus.Awaiting};

    if (photoElements.every(pe => pe.currentStatus === SimpleStatus.Accepted)) {
      state.color = StatusIconColor.Success;
      state.icon = StatusIcon.Accepted;
      state.simpleStatus = SimpleStatus.Accepted;

    } else if(photoElements.some(pe => pe.currentStatus === SimpleStatus.Submitted)){
      state.color = StatusIconColor.Submitted;
      state.icon = StatusIcon.Submitted;
      state.simpleStatus = SimpleStatus.Submitted;


    } else if(photoElements.some(pe => pe.currentStatus === SimpleStatus.Flagged)){
      state.color = StatusIconColor.Warning;
      state.icon = StatusIcon.Flagged;
      state.simpleStatus = SimpleStatus.Flagged;

    } else if(photoElements.some(pe => pe.currentStatus === SimpleStatus.Rejected)){
      state.color = StatusIconColor.Danger;
      state.icon = StatusIcon.Rejected;
      state.simpleStatus = SimpleStatus.Rejected;

    }

    return state;
  }

  updateFullSizeCaptureElementFromCaptureUpdate(captureUpdate: CaptureUpdate, elementToUpdate: FullSizeCaptureElement) {
   const updateCaptureStatus = captureUpdate.status !== elementToUpdate.captureStatus && captureUpdate.status !== PhotoCaptureStatus.NoChange && captureUpdate.status !== PhotoCaptureStatus.Renewing;

     if (updateCaptureStatus && elementToUpdate) {
       const captureState = this.setCaptureState(captureUpdate.status);
       elementToUpdate.captureStatusIcon = captureState.icon;
       elementToUpdate.captureStatusColor = captureState.color;
       elementToUpdate.captureStatus = captureState.status;
     }

     if (captureUpdate.allNotes && elementToUpdate) {
       const captureNotes = this.createsNotesList(captureUpdate.allNotes);
       captureNotes.reverse();
       elementToUpdate.notes = captureNotes;
     }
   }

  updatePhotoFromCaptureUpdate(captureUpdate: CaptureUpdate) {
    this.zone.run(async ()=> {
      const elementToUpdate = this.photoElementList.find(pe => pe.photoCode === captureUpdate.code.substring(0, pe.photoCode.length));
      const photoCaptures = this.jobService.currentViewedJobCaptures
        .filter(capture => capture.Code.substring(0, elementToUpdate.photoCode.length) === elementToUpdate.photoCode);

      const latestPhotoState = this.setPhotoStateFromCaptureUpdate(captureUpdate.status);

      if (!elementToUpdate.isSeries && photoCaptures.some(capture => capture.Status === PhotoCaptureStatus.Accepted)) {
        elementToUpdate.isComplete = true;
        elementToUpdate.currentStatus = SimpleStatus.Accepted;
        elementToUpdate.currentStatusColor = StatusIconColor.Success;
        elementToUpdate.currentStatusIcon = StatusIcon.Accepted;

      }else {
        if (latestPhotoState) {
          elementToUpdate.currentStatus = latestPhotoState.status;
          elementToUpdate.currentStatusColor = latestPhotoState.color;
          elementToUpdate.currentStatusIcon = latestPhotoState.icon;
        }
      }
      if (elementToUpdate.isSeries && photoCaptures.every(capture => capture.Status === PhotoCaptureStatus.Accepted)) {
        elementToUpdate.isComplete = true;
        elementToUpdate.currentStatus = SimpleStatus.Accepted;
        elementToUpdate.currentStatusColor = StatusIconColor.Success;
        elementToUpdate.currentStatusIcon = StatusIcon.Accepted;
      } else if (elementToUpdate.isSeries && !photoCaptures.every(capture => capture.Status === PhotoCaptureStatus.Accepted)) {
        if (latestPhotoState) {
          elementToUpdate.isComplete = false;
          elementToUpdate.currentStatus = latestPhotoState.status;
          elementToUpdate.currentStatusColor = latestPhotoState.color;
          elementToUpdate.currentStatusIcon = latestPhotoState.icon;
        }
      }
      this.updatedPhotoElement$.next(elementToUpdate);
      this.photoLogSectionElementsSubject.next(this.photoLogSectionElements);
    });
  }

  private updatePhotoElementFromNewCapture(newCapture: Capture){
    this.zone.run(async ()=> {

      const elementToUpdate = this.photoElementList.find(pe => pe.photoCode === newCapture.Code.substring(0, pe.photoCode.length));
      if (elementToUpdate) {

        this.localStorageService.readImageFileData(Filesystem_JobFolder_PhotosFolders.TileLg,
            newCapture.Code.substring(0, newCapture.Code.indexOf('_')),
            newCapture.FileName)
        .then(result => {
          elementToUpdate.thumbnailCaptureData = BASE64_IMAGE_PREFIX + result.data;
          elementToUpdate.fileName = newCapture.FileName;
          elementToUpdate.currentStatus = newCapture.Status;
          elementToUpdate.currentStatusColor = StatusIconColor.Submitted;
          elementToUpdate.currentStatusIcon = StatusIcon.Submitted;
          elementToUpdate.photoCapturesCount = elementToUpdate.photoCapturesCount ? 1 : +1;
          this.photoLogSectionElementsSubject.next(this.photoLogSectionElements);
          this.updatedPhotoElement$.next(elementToUpdate);
          this.capture$.next(newCapture);
        })
        .catch((error: Error) => console.log('Error reading file data: ', error.message));
      }
    });
  }

  // updates the photo element if it is a series
  private updatePhotoSeries(photoElement: PhotoElement){
    const elementToUpdate = this.photoElementList.find(pe => pe.photoCode === photoElement.photoCode);
    if (elementToUpdate) {
      elementToUpdate.currentStatus = SimpleStatus.Accepted;
      elementToUpdate.currentStatusColor = StatusIconColor.Success;
      elementToUpdate.currentStatusIcon = StatusIcon.Accepted;
      elementToUpdate.isComplete = true;
      if (!elementToUpdate.photoCapturesCount) {
        elementToUpdate.thumbnailCaptureData = AWAITING_IMAGE_PATH;
      } else{
        elementToUpdate.thumbnailCaptureData = photoElement.thumbnailCaptureData;
      }
      this.photoLogSectionElementsSubject.next(this.photoLogSectionElements);
      this.updatedPhotoElement$.next(elementToUpdate);
    }
  }

  private async getStoredImages(jobFolderCode: string) {
    //used to store the lo res files names, so it can be used when loading full size captures
    await this.localStorageService.getSavedImages(Filesystem_JobFolder_PhotosFolders.LoRes, jobFolderCode)
    .then(result => {
      this.loResFilesNames = result.files.map(f => f.name);
    })
    .catch(() => this.loResFilesNames = []);

    await this.localStorageService.getSavedImages(Filesystem_JobFolder_PhotosFolders.TileLg, jobFolderCode)
    .then(result => {
      this.thumbnailFilesNames = result.files.map(f => f.name);
    })
    .catch(() => this.thumbnailFilesNames = []);
  }

  private async readStoredThumbnails(jobFolderCode: string){
    for (const fileName of this.thumbnailFilesNames) {
        await this.localStorageService.readImageFileData(Filesystem_JobFolder_PhotosFolders.TileLg, jobFolderCode, fileName)
        .then((result) => {
          this.jobStoredThumbnails.push({
            Name: fileName,
            Data: BASE64_IMAGE_PREFIX + result.data,
          });
        })
        .catch((error: Error) => console.log(error.message));
      }
  }

  //creates the photo log section elements for ease of displaying
  private createPhotoLogSectionElements() {
    const photoLogSectionElements: PhotoLogSectionElement[] = [];
    this.currentJob.JobFolders[0].PhotoLogs[0].PhotoLogSections.forEach(pls => {
      const sectionCaptures = this.jobService.currentViewedJobCaptures.filter(capture => capture.Code.substring(0, pls.Code.length) === pls.Code);

      //photoset elements for the photo log section
      const photoSetElements: PhotoSetElement[] = this.createPhotoSetElements(pls);
      let requiredPhotos = 0;
      let photoElements: PhotoElement[] = [];
      photoSetElements.forEach(pse => {
        requiredPhotos += pse.photoElements.length;
        photoElements = photoElements.concat(pse.photoElements);
      });

      const sectionState = this.setElementStateFromPhotoElements(photoElements);
      const photoLogSectionElement: PhotoLogSectionElement = {
        name: this.lexiconService.setNameFromSpecifierValues(pls.SpecifierValues),
        photoLogSectionCode: pls.Code,
        photoLogCode: this.currentJob.JobFolders[0].PhotoLogs[0].Code,
        requiredPhotos,
        captures: sectionCaptures,
        specifierValues: pls.SpecifierValues,
        photoSetElements,
        currentStatusIcon: sectionState.icon,
        currentStatusColor: sectionState.color,
      };
      photoLogSectionElements.push(photoLogSectionElement);
      this.photoLogSectionElements.push(photoLogSectionElement);
    });
    this.photoLogSectionElementsSubject.next(photoLogSectionElements);
  }

  createFullSizeCaptureElementsArray(photoElement: PhotoElement){
    const fullSizeElements: FullSizeCaptureElement[] = [];
    // captures for the given photo
    const filteredCaptures = this.jobService.currentViewedJobCaptures.filter(c => c.Code.substring(0, photoElement.photoCode.length) === photoElement.photoCode)

    filteredCaptures.forEach((capture: Capture) => {
      const fullSizeElement = this.createFullSizeCaptureElement(capture);

      fullSizeElements.push(fullSizeElement);
    });

    return fullSizeElements;
  }

  private createFullSizeCaptureElement(capture: Capture): FullSizeCaptureElement {
    const parentPhotoElement = this.photoElementList.find(pe => pe.photoCode === capture.Code.substring(0, pe.photoCode.length));

    const captureState: {color: string; icon: string} = this.setCaptureState(capture.Status);
    let captureNotes: Note[] = [];
    const capturedDateExtracted: number = capture.CapturedDate ? this.extractDate(capture.CapturedDate) : null;
    const capturedDateUTCExtracted: number = capture.CapturedUtcDate ? this.extractDate(capture.CapturedUtcDate) : null;

    if (capture.Notes) {
      captureNotes = this.createsNotesList(capture.Notes);
      captureNotes.reverse();
    }

    const element: FullSizeCaptureElement = {
      jobFolderCode: this.jobService.currentViewedJob.JobFolders[0].Code,
      captureCode: capture.Code,
      capturedDate: capturedDateExtracted? new Date(capturedDateExtracted) : null,
      capturedDateUTC: capturedDateUTCExtracted ? new Date(capturedDateUTCExtracted) : null,
      notes: captureNotes,
      captureStatus: capture.Status,
      captureStatusColor: captureState.color,
      captureStatusIcon: captureState.icon,
      fileName: capture.FileName,
      displayOrder: parentPhotoElement.photoDisplayOrder,
      isFullSizeDownloaded: this.loResFilesNames.includes(capture.FileName)
    };

    

    return element;
  }

  private createsNotesList(notesString: string): Note[] {
    const notesFormatted: Note[] = [];
    const notesList = notesString.split('>');

    //removes last empty element of the array
    notesList.pop();

    notesList.forEach(n => {

      const noteInfoList = n.split('<by:');
      const noteExtraInfo = noteInfoList[1].split(';');
      const author = noteExtraInfo[0]?? noteExtraInfo[0];
      const date:number = this.createDateFromString(noteExtraInfo[1].substring(noteExtraInfo[1].indexOf(':') + 1));

      const note: Note = {
        text: noteInfoList[0]?? noteInfoList[0],
        author: author ? author : 'appuser',
        date,
        status: noteExtraInfo[2]? Number(noteExtraInfo[2].substring(noteExtraInfo[2].indexOf(':') + 1)) : null,
        overlay: noteExtraInfo[3]?? noteExtraInfo[3]
      };
      notesFormatted.push(note);
    });

    const notes: Note[] = notesFormatted;
    return notes;
  }
  private createDateFromString(inputString: string): number {
    const year = inputString.slice(0, 2);
    const month = inputString.slice(2, 4);
    const day = inputString.slice(4, 6);
    const hours = inputString.slice(6, 8);
    const seconds = inputString.slice(8, 10);

    const dateString = `20${year}-${month}-${day}T${hours}:${seconds}:00+0000`;
    const dateObj = new Date(dateString);
    //console.log("dateString dateObj", dateString,dateObj.getTime())

    return dateObj.getTime();
  }

  private setCaptureState(captureStatus: number): {color: string; icon: string; status: number}{
    switch (captureStatus) {

      case PhotoCaptureStatus.Rejected:
        return {color: StatusIconColor.Danger, icon: StatusIcon.Rejected, status: PhotoCaptureStatus.Rejected};

      case PhotoCaptureStatus.Flagged:
        return {color: StatusIconColor.Warning, icon: StatusIcon.Flagged, status: PhotoCaptureStatus.Flagged};

      case PhotoCaptureStatus.Created:
        return {color: StatusIconColor.Submitted, icon: StatusIcon.Submitted, status: PhotoCaptureStatus.Created};

      case PhotoCaptureStatus.Accepted:
        return {color: StatusIconColor.Success, icon: StatusIcon.Accepted, status: PhotoCaptureStatus.Accepted};

      case PhotoCaptureStatus.NotApplicable:
      case PhotoCaptureStatus.Recoded:
      default:
        return {color: StatusIconColor.Warning, icon: StatusIcon.Camera, status: PhotoCaptureStatus.NotApplicable};
    };
  }

  //creates the photo elements for ease of displaying
  private createPhotoSetElements(pls: PhotoLogSection): PhotoSetElement[] {
    const photoSetElements: PhotoSetElement[] = [];

    let photoCount = 1;
    for (const ps of pls.PhotoSets) {
      const photoSetElement: PhotoSetElement = {
        parentPhotoLogSectionCode: pls.Code,
        photoElements: [],
        comments: ps.Comments ? [ps.Comments] : [],
        specifierValues: ps.SpecifierValues,
        displayOrder: ps.DisplayOrder,
        photoSetCode: ps.Code,
        name: this.lexiconService.setNameFromSpecifierValues(ps.SpecifierValues),
        requiredPhotos: ps.Photos.length,
        currentStatusIcon: StatusIcon.Awaiting,
        currentStatusColor: StatusIconColor.Warning,
        curentStatus: SimpleStatus.Awaiting,
        photoSetCapturesCount: 0,
      };

      for (const photo of ps.Photos) {
        const photoCaptures = this.jobService.currentViewedJobCaptures.filter(c => c.Code.startsWith(photo.Code));
        const photoElement: PhotoElement = this.createPhotoElement(photoCaptures, photo, ps.SpecifierValues);
        photoElement.parentPhotoSetDisplayOrder = ps.DisplayOrder;
        photoElement.photoDisplayOrder = photoCount;
        photoElement.parentPhotoLogSectionCode = pls.Code;
        photoElement.parentPhotoSetCode = ps.Code;
        photoElement.parentPhotoLogSectionSpecifierValues = pls.SpecifierValues;

        photoSetElement.photoSetCapturesCount += photo.CaptureCount ?? 0;
        photoSetElement.photoElements.push(photoElement);
        photoCount++;
      }

      const latestPhotoSetState = this.setElementStateFromPhotoElements(photoSetElement.photoElements);
      photoSetElement.curentStatus = latestPhotoSetState.simpleStatus;
      photoSetElement.currentStatusIcon = latestPhotoSetState.icon;
      photoSetElement.currentStatusColor = latestPhotoSetState.color;

      photoSetElements.push(photoSetElement);
    }

    return photoSetElements;
  }

  private createPhotoElement(photoCaptures: Capture[], photo: Photo, photoSetSpecifierValues: string): PhotoElement {
    const photoSpecifierValues = photoSetSpecifierValues + '&' + photo.SpecifierValues;
    const photoLatestState = this.setLatestPhotoState(photoCaptures, photo);
    let storedThumbnail = null;

    if (photoCaptures.length > 0) {
      storedThumbnail = this.jobStoredThumbnails.find(t => t.Name === photoCaptures[0].FileName);
    }

    const photoElement: PhotoElement = {
      thumbnailCaptureData: storedThumbnail ? storedThumbnail.Data : null,
      photoCaptureStatus: photoCaptures[photoLatestState.thumbnailIndex] ? photoCaptures[photoLatestState.thumbnailIndex].Status : null,
      photoCode: photo.Code,
      name: this.lexiconService.setNameFromSpecifierValues(photoSpecifierValues),
      photoCapturesCount: photoCaptures.length > 0 ? photoCaptures.length : null,
      longNameSpecifierValues: photoSpecifierValues,
      photoSpecifierValues: photo.SpecifierValues,
      parentPhotoSetSpecifierValues: photoSetSpecifierValues,
      currentStatusColor: photoLatestState.color,
      currentStatus: photoLatestState.simpleStatus,
      currentStatusIcon: photoLatestState.icon,
      comments: [],
      isSeries: photo.IsSeries,
      isComplete: photo.IsComplete,
      fileName: storedThumbnail ? storedThumbnail.Name : null,
      parentPhotoLogSectionCode: '',
      parentPhotoLogSectionSpecifierValues: '',
      parentPhotoSetCode: ''
    };

    if (photo.Comments) {
      photoElement.comments.push(photo.Comments);
    }

    this.photoElementList.push(photoElement);
    return photoElement;
  }

  //this method sets latest thumbnail to display, the latest photo status,
  //latest photo icon and the latest photo status color
  private setLatestPhotoState(photoCaptures: Capture[], photo: Photo) {
    //default state
    const photoState = {
      thumbnailIndex: 0,
      color: StatusIconColor.Warning,
      //used to keep track of the photo status flags
      status: 0,
      //used for basic check e.g. photo log section can check against it,
      //to display its own status
      simpleStatus: SimpleStatus.Awaiting,
      icon: StatusIcon.Awaiting,
    };

    //set flags from photo
    photoState.status += this.setStatusFlagsFromPhoto(photo);

    if (photoCaptures.length > 0) {
      //set photo state from captures
      this.setStatusFlagsFromCaptures(photoCaptures, photoState, photo.IsSeries);
    }

    //set latest photo icons and colors to display
    this.setPhotoLatestIconAndColor(photoState, photoCaptures);

    return photoState;
  }

  private setStatusFlagsFromPhoto(photo: Photo): number {
    let status = 0;

    if (photo.IsComplete) {
      status += PhotoStatus.Completed;
    }
    if (photo.IsSeries) {
      status += PhotoStatus.IsSeries;
    }
    if (photo.IsRequired) {
      status += PhotoStatus.IsRequired;
    }
    if (photo.IsPriority) {
      status += PhotoStatus.IsPriority;
    }

    return status;
  }

  private setStatusFlagsFromCaptures(photoCaptures: Capture[], photoState: any, isSeries: boolean) {
    //the order of the if statements matters as this indicates the importance
    //of the flags e.i. latest statement is most important
    if (photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Deleted)) {
      photoState.status += PhotoStatus.Default; //flag
      photoState.thumbnailIndex = photoCaptures.indexOf(photoCaptures.find((c) => c.Status === PhotoCaptureStatus.Deleted));
    }

    if (!isSeries && photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Accepted)) {
      photoState.status += PhotoStatus.Accepted; //flag
      photoState.thumbnailIndex = photoCaptures.indexOf(photoCaptures.find((c) => c.Status === PhotoCaptureStatus.Accepted));
    }

    if (isSeries && photoCaptures.every((c) => c.Status === PhotoCaptureStatus.Accepted)) {
      photoState.status += PhotoStatus.Accepted; //flag
      photoState.thumbnailIndex = photoCaptures.indexOf(photoCaptures.find((c) => c.Status === PhotoCaptureStatus.Accepted));
    }

    if (photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Created)) {
      photoState.status += PhotoStatus.Default; //flag
      photoState.thumbnailIndex = photoCaptures.indexOf(photoCaptures.find((c) => c.Status === PhotoCaptureStatus.Created));
    }

    if (photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Rejected)) {
      photoState.status += PhotoStatus.Rejected; //flag
      photoState.thumbnailIndex = photoCaptures.indexOf(photoCaptures.find((c) => c.Status === PhotoCaptureStatus.Rejected));
    }

    if (photoCaptures.some((c) => c.Status === PhotoCaptureStatus.Flagged)) {
      photoState.status += PhotoStatus.Flagged; //flag
      photoState.thumbnailIndex = photoCaptures.indexOf(photoCaptures.find((c) => c.Status === PhotoCaptureStatus.Flagged));
    }
  }

  private setPhotoLatestIconAndColor(photoState, photoCaptures: Capture[]) {
    if ((photoState.status & PhotoStatus.Completed) || (photoState.status & PhotoStatus.Accepted)) {
      photoState.color = StatusIconColor.Success;
      photoState.icon = StatusIcon.Accepted;
      photoState.simpleStatus = SimpleStatus.Accepted;
    } else {
      if ((photoState.status & PhotoStatus.Flagged)) {
        photoState.color = StatusIconColor.Warning;
        photoState.icon =  StatusIcon.Flagged;
        photoState.simpleStatus = SimpleStatus.Flagged;
        } else {
          if ((photoState.status & PhotoStatus.Rejected)) {
            photoState.color = StatusIconColor.Danger;
            photoState.icon =  StatusIcon.Rejected;
            photoState.simpleStatus = SimpleStatus.Rejected;
          } else {
            if ((photoState.status & PhotoStatus.IsPriority)) {
              photoState.color = StatusIconColor.Warning;
              photoState.icon =  StatusIcon.IsPriority;
              photoState.simpleStatus = SimpleStatus.Awaiting;
            } else {
              if ((photoState.status & PhotoStatus.IsSeries)) {
                photoState.color = StatusIconColor.Warning;
                photoState.icon =  StatusIcon.IsSeries;
                photoState.simpleStatus = SimpleStatus.Awaiting;
              } else {
                if ((photoState.status & PhotoStatus.IsRequired)) {
                  photoState.color = StatusIconColor.Warning;
                  photoState.icon =  StatusIcon.Awaiting;
                  photoState.simpleStatus = SimpleStatus.Awaiting;
                } else {
                  photoState.color = StatusIconColor.Warning;
                  photoState.icon =  StatusIcon.Awaiting;
                  photoState.simpleStatus = SimpleStatus.Awaiting;
                }
              }
            }
          }
        }

      if (photoCaptures.length > 0) {
        //check if the the current photo thumbnails has been created,
        //if so set the status to submitted
        if (photoCaptures[photoState.thumbnailIndex].Status === PhotoCaptureStatus.Created) {
          photoState.color = StatusIconColor.Submitted;
          photoState.icon = StatusIcon.Submitted;
          photoState.simpleStatus = SimpleStatus.Submitted;
        }
      }
    }
  }

  private setPhotoStateFromCaptureUpdate(captureUpdateStatus: number){
    switch (captureUpdateStatus) {
      case PhotoCaptureStatus.Rejected:
        return {color: StatusIconColor.Danger, icon: StatusIcon.Rejected, status: SimpleStatus.Rejected};

      case PhotoCaptureStatus.Deleted:
       return {color: StatusIconColor.Danger, icon: StatusIcon.Rejected, status: SimpleStatus.Rejected};

       case PhotoCaptureStatus.Flagged:
        return {color: StatusIconColor.Warning, icon: StatusIcon.Flagged, status: SimpleStatus.Flagged};
      case PhotoCaptureStatus.NoChange:
      case PhotoCaptureStatus.Recoded:
        return null;
      default:
        return {color: StatusIconColor.Warning, icon: StatusIcon.Camera, status: SimpleStatus.Awaiting};
    }
  }

}
