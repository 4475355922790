/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { AlertController, ModalController } from '@ionic/angular';
import { AWAITING_IMAGE_PATH, BASE64_IMAGE_PREFIX, FILESYSTEM_JOBS_FOLDER, STORAGE_JOB_CAPTURES, STORAGE_MY_JOBS, STORAGE_MY_LOCATION } from 'src/app/shared/lookups/consts';
import { Note } from 'src/app/shared/models/note';
import { PhotoElement } from 'src/app/shared/models/photo-element';
import { CaptureService } from 'src/app/shared/services/capture.service';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { BlobFileType, CaptureEventType, FileSystem_JobFolders, Filesystem_JobFolder_PhotosFolders, JobStatus, PhotoCaptureStatus, SimpleStatus, StatusIcon, StatusIconColor } from 'src/app/shared/lookups/enums';
import { FileService } from 'src/app/shared/services/file.service';
import { FullSizeCaptureElement } from 'src/app/shared/models/full-size-capture-element';
import { JobService } from 'src/app/shared/services/job.service';
import { Capture } from 'src/app/shared/models/capture';
import { LexiconService } from 'src/app/shared/services/lexicon.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { PhotosPageService } from 'src/app/shared/services/photos-page.service';
import { PhotoLogSectionElement } from 'src/app/shared/models/photo-log-section-element';
import { Job } from 'src/app/shared/models/job';
import { Base64File, BlobFile } from 'src/app/shared/models/CustomFiles';
import { PhotoSetElement } from 'src/app/shared/models/photo-set-element';
import { LoadingOverlayService } from 'src/app/shared/services/loading-overlay.service';
import { APPGeoLocationService } from 'src/app/shared/services/app.location.service';
import { Position } from '@capacitor/geolocation';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-take-photo-modal',
  templateUrl: './take-photo-modal.component.html',
  styleUrls: ['./take-photo-modal.component.scss'],
})
export class TakePhotoModalComponent implements OnInit, OnDestroy {
  @Input() photoElement: PhotoElement;
  @Input() inputPhoto: Photo;
  @Output() newFullSizeElement = new EventEmitter<FullSizeCaptureElement>();
  @ViewChild('completeSeriesButton', { read: ElementRef }) completeSeriesButton: ElementRef;
  @ViewChild('openCameraButton', { read: ElementRef }) openCameraButton: ElementRef;

  //TODO: needs a discussion what should be used as a user. users name, phone model etc.
  currentUser = 'appuser';
  currentJob: Job;
  image = AWAITING_IMAGE_PATH;
  photo: Photo;
  isCaptureUploaded = false;
  takePhotoText = 'Take Photo';
  captureCount: number;
  canUpload = false;
  captureCode: string;
  geoLocation: Position;
  capture: Capture;
  fullSizeCaptureElement: FullSizeCaptureElement;
  captureNote: Note = null;

  // this is used to unsubscribe any/all subscriptions when the component is destroyed
  // to prevent memory leaks
  private componentDestroyed$ = new Subject<void>();

  constructor(
    private modalCtrl: ModalController,
    private jobService: JobService,
    private captureService: CaptureService,
    private lexiconService: LexiconService,
    private toastMessageService: ToastMessageService,
    private overlayService: LoadingOverlayService,
    private alertCtrl: AlertController,
    private fileService: FileService,
    private localStorageService: LocalStorageService,
    private photosPageService: PhotosPageService,
    private locationService: APPGeoLocationService,
    private zone: NgZone,
  ) { }
  
  ngOnInit() {
    this.captureCount = this.photoElement.photoCapturesCount ? this.photoElement.photoCapturesCount : 0;
    this.currentJob = this.jobService.currentViewedJob;
    
    this.locationService.currentPosition$()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (position: Position) => {
        if (position) {
          this.geoLocation = position;          
        }
      }
    });
    this.localStorageService.getFromStorage(STORAGE_MY_LOCATION).then((location) => {
      this.geoLocation = location;
      
      
      if (this.inputPhoto) {
        const currentTime = Date.now();
        const captureCountString = ('0' + this.captureCount).slice(-2);
        this.captureCode = this.photoElement.photoCode + '_' + captureCountString + '_' + currentTime;
        this.createCapture(currentTime);
        this.image = BASE64_IMAGE_PREFIX + this.inputPhoto.base64String;
        this.takePhotoText = 'Retake Photo';
        this.photo = this.inputPhoto;
        this.canUpload = true;
      }
    });
  }
  
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete(); 
  }
  
  async openCamera() {
    const isLocationOn = await this.locationService.isLocationServiceOn();
    
    if (!isLocationOn) {
      this.toastMessageService.showToastMessage('Please turn on the location.', 'warning');
      return;
    }
    
    // get and set the current user's latest position
    const latestPosition = await this.locationService.getCurrentUserLocation();    
    if (latestPosition) {
      this.geoLocation = latestPosition;
    }
    
    // check if we have valid most recent user's position
    if (!this.locationService.isLocationValid(this.geoLocation)) {
      this.toastMessageService.showToastMessage('Failed to get your recent position.', 'warning');
      return;
    }

    //set the capture count to initial value if we retake the capture
    if (this.photo) {
      this.captureCount = this.photoElement.photoCapturesCount ? this.photoElement.photoCapturesCount : 0;
    }
    //display camera
    const takenPhoto = await Camera.getPhoto({
      source: CameraSource.Camera,
      allowEditing: false,
      quality: 70,
      resultType: CameraResultType.Base64
    }).catch((error: Error) => console.log(error.message));


    if (takenPhoto) {
      // await this.getGeoLocation().then(position => this.geoLocation = position);
      const currentTime = Date.now();
      const captureCountString = ('0' + this.captureCount).slice(-2);
      this.captureCode = this.photoElement.photoCode + '_' + captureCountString + '_' + currentTime;
      this.createCapture(currentTime);
      this.image = BASE64_IMAGE_PREFIX + takenPhoto.base64String;
      this.takePhotoText = 'Retake Photo';
      this.photo = takenPhoto;
      this.canUpload = true;
      this.isCaptureUploaded = false;
    }
  }

  async upload() {
    this.canUpload = false;
    this.takePhotoText = 'Take Another Photo';
    this.zone.run(async () => {
      const fsce = await this.createFullSizeCaptureElement(this.capture);

      if (fsce) {
        this.newFullSizeElement.emit(fsce);
        this.fullSizeCaptureElement = fsce;
      }
    });

    const captureNote = this.captureNote? this.captureNote.text : '';

    let xmlBlob: Blob;
    await this.fileService.createXMLBlob(this.currentUser, captureNote, this.geoLocation, CaptureEventType.ImageCapture).then((blob: Blob) => xmlBlob = blob);

    const imageBlob: Blob = this.fileService.createFileBlobFromBase64String(this.photo.base64String, BlobFileType.Image);
    const filename = this.capture.Code;

    // upload xml file first
    this.captureService.uploadFile(xmlBlob, filename + '.xml').then(async () => {

      //upload the capture
      await this.captureService.uploadFile(imageBlob, filename + '.jpg.thumb.jpg').then(() => {

        this.isCaptureUploaded = true;
        this.toastMessageService.showToastMessage('Capture uploaded successfully.');
      })
      .catch((error: Error) => {
        this.toastMessageService.showToastMessage('An error occured when uploading.');

        const imageFile: BlobFile = {name: filename + '.jpg.thumb.jpg', data: imageBlob};
        this.localStorageService.saveFailedUploadFileToFilesystem(imageFile)
        .catch( (error: Error)=> {
          this.toastMessageService.showToastMessage(`Failed upload file not saved. Message: ${error.message}`, 'danger');
        });
      });
    })
    .catch( (error: Error) => {
      this.toastMessageService.showToastMessage('An error occured when uploading.');

      const xmlFile: BlobFile = {name: filename + '.xml', data: xmlBlob};
      const imageFile: BlobFile = {name: filename + '.jpg.thumb.jpg', data: imageBlob};

      // save xml file that failed to be uploaded
      this.localStorageService.saveFailedUploadFileToFilesystem(xmlFile)
      .catch( (error: Error)=> {
        this.toastMessageService.showToastMessage(`Failed metadata upload file not saved. Message: ${error.message}`, 'danger');
      });

      // save image file that failed to be uploaded
      this.localStorageService.saveFailedUploadFileToFilesystem(imageFile)
      .catch( (error: Error)=> {
        this.toastMessageService.showToastMessage(`Failed image upload file not saved. Message: ${error.message}`, 'danger');
      });

    });

    this.captureCount += 1;
    this.isCaptureUploaded = true;
    this.updateJob();

    this.photoElement.photoCapturesCount += 1;
    if (!this.photoElement.isSeries) {
      this.modalCtrl.dismiss(this.photo.base64String);
    }
  }

  updateJob() {
    this.updateJobStatus();
    this.updateJobCaptures();
    this.updatePhotoLogSection();
    this.updateStoredJob();
  }

  //updates the values for the photo entity for the stored job
  async updateStoredJob(isPhotoComplete: boolean = null) {
    //update capture count photo entity
    const jobPhotoEntity = this.currentJob.JobFolders[0].PhotoLogs[0].PhotoLogSections
      .find(pls => pls.Code === this.photoElement.parentPhotoLogSectionCode).PhotoSets
      .find(ps => ps.Code === this.photoElement.parentPhotoSetCode).Photos
      .find(p => p.Code === this.photoElement.photoCode);

    if (isPhotoComplete) {
      jobPhotoEntity.IsComplete = true;
    }

    jobPhotoEntity.CaptureCount = this.captureCount;
    return this.localStorageService.saveUpdatedJob(this.currentJob.Code);
  }

  updateJobStatus() {
    //check if the job has the status created, if so change to started
    if (this.currentJob.JobStatus === JobStatus.Created) {
      this.localStorageService.storedJobs.find(job => job === this.currentJob).JobStatus = JobStatus.Started;
      this.localStorageService.writeToStorage(STORAGE_MY_JOBS, this.localStorageService.storedJobs)
        .catch((error: Error) => console.log(error.message));
    }
  }

  updateJobCaptures() {
    //update job card capture count
    let capturesObject: { jobCode: string; jobFolderCode: string; captures: Capture[] } = this.localStorageService.storedJobsCaptures.find(cobj => cobj.jobCode === this.currentJob.Code);
    if (!capturesObject) {
      capturesObject = { jobCode: this.currentJob.Code, jobFolderCode: this.currentJob.JobFolders[0].Code, captures: [] };
    }

    capturesObject.captures.push(this.capture);
    this.localStorageService.storedJobCaptures$.next(capturesObject);

    //update section captures count
    this.photosPageService.capture$.next(this.capture);

    //save the new capture
    this.localStorageService.writeToStorage(STORAGE_JOB_CAPTURES + this.currentJob.Code, this.jobService.currentViewedJobCaptures)
      .catch((error: Error) => console.log(error.message));
  }

  async updatePhotoLogSection() {

    const photoLogSectionElement = this.photosPageService.photoLogSectionElements.find((pls: PhotoLogSectionElement) => pls.photoLogSectionCode === this.photoElement.photoCode.substring(0, pls.photoLogSectionCode.length));

    if (photoLogSectionElement) {
      // update photo log section status
      photoLogSectionElement.currentStatusColor = StatusIconColor.Submitted;
      photoLogSectionElement.currentStatusIcon = StatusIcon.Submitted;

      // update photo set status
      const photoSet = photoLogSectionElement.photoSetElements.find((ps: PhotoSetElement) => ps.photoSetCode === this.photoElement.photoCode.substring(0, ps.photoSetCode.length))
      photoSet.currentStatusColor = StatusIconColor.Submitted;
      photoSet.currentStatusIcon = StatusIcon.Submitted;

    }

    if (this.fullSizeCaptureElement) {
      let pe: PhotoElement = null;

      pe = photoLogSectionElement.photoSetElements.find(pse => pse.photoSetCode === this.photoElement.photoCode.substring(0, pse.photoSetCode.length))
        .photoElements.find(pEl => pEl.photoCode === this.photoElement.photoCode);
      if (pe) {
        if (pe.currentStatus !== SimpleStatus.Submitted && pe.currentStatus !== SimpleStatus.Accepted) {
          pe.currentStatus = SimpleStatus.Submitted;
          pe.currentStatusColor = StatusIconColor.Submitted;
          pe.currentStatusIcon = StatusIcon.Submitted;
          pe.fileName = this.fullSizeCaptureElement.fileName;
        }

        //save full size image
        const file: Base64File = {name: this.fullSizeCaptureElement.fileName, data: this.fullSizeCaptureElement.fullSizeCaptureData};
        const filePath = `${FILESYSTEM_JOBS_FOLDER}/${this.currentJob.JobFolders[0].Code}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.LoRes}/${file.name}`;
        await this.localStorageService.saveBase64ImageToFileSystem(file, filePath);

        // add to full size image name to a reference array so we know we have the full size already on the device
        this.photosPageService.loResFilesNames.push(file.name);

        //resize image and set as a new thumbnail
        this.fileService.resizeImage(this.fullSizeCaptureElement.fullSizeCaptureData, 150, 150).then(async (result: string) => {
          pe.thumbnailCaptureData = result;

          //save resized image as thumbnail
          const resizedFile: Base64File = {name: this.fullSizeCaptureElement.fileName, data: pe.thumbnailCaptureData};
          const resizedFilePath = `${FILESYSTEM_JOBS_FOLDER}/${this.currentJob.JobFolders[0].Code}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.TileLg}/${file.name}`;
          await this.localStorageService.saveBase64ImageToFileSystem(resizedFile, resizedFilePath);});
      }
    }
    this.photosPageService.photoLogSectionElementsSubject.next(this.photosPageService.photoLogSectionElements);
  }

  async createFullSizeCaptureElement(capture: Capture): Promise<FullSizeCaptureElement> {
    return new Promise(async (resolve, reject) => {
      try {
        const capturedDateExtracted: number = capture.CapturedDate ? this.photosPageService.extractDate(capture.CapturedDate) : null;
        const capturedDateUTCExtracted: number = capture.CapturedUtcDate ? this.photosPageService.extractDate(capture.CapturedUtcDate) : null;
        const fsce = {
          jobFolderCode: this.currentJob.JobFolders[0].Code,
          captureCode: capture.Code,
          capturedDate: capturedDateExtracted ? new Date(capturedDateExtracted) : null,
          capturedDateUTC: capturedDateUTCExtracted ? new Date(capturedDateUTCExtracted) : null,
          notes: this.captureNote == null ? [] : [this.captureNote],
          captureStatus: capture.Status,
          captureStatusColor: StatusIconColor.Submitted,
          captureStatusIcon: StatusIcon.Submitted,
          fileName: capture.FileName,
          isFullSizeDownloaded: true,
          parentPhotoCode: this.photoElement.photoCode,
          fullSizeCaptureData: BASE64_IMAGE_PREFIX + this.photo.base64String,
          displayOrder: this.photoElement.photoDisplayOrder
        };
        // this.newFullSizeElement.emit(this.fullSizeCaptureElement);
        resolve(fsce);
      } catch (error) {
        reject(error);
      }
    });
  }

  createCapture(currentTime: number) {
    const timeDifference = this.setTimeDifferenceStringFromDate(new Date(currentTime));
    this.capture = {
      CapturedDate: `/Date(${currentTime}${timeDifference})/`,
      CapturedUtcDate: `/Date(${currentTime})/`,
      Code: this.captureCode,
      ExportRotate: 0,
      FileName: this.captureCode + '.jpg',
      FullName: this.currentJob.Site.Code + ' ' + this.lexiconService.setNameFromSpecifierValues(this.photoElement.longNameSpecifierValues),
      LongName: this.lexiconService.setNameFromSpecifierValues(this.photoElement.longNameSpecifierValues),
      ShortName: this.lexiconService.setNameFromSpecifierValues(this.photoElement.photoSpecifierValues),
      Status: PhotoCaptureStatus.Created
    };
  }

  async presentAddNoteModal() {
    const alert = await this.alertCtrl.create({
      header: 'Add Note',
      cssClass: 'alert-main-container',
      inputs: [
        {
          name: 'note',
          type: 'text',
          cssClass: 'alert-input'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-cancel-button'
        },
        {
          text: 'Add Note',
          cssClass: 'alert-confirm-button',
          handler: (inputs: { note: string}) => {this.addNote(inputs);}
        }
      ]
    });

    await alert.present().then(() => {
      //set focus on the input field
      const firstInput: any = document.querySelector('ion-alert input');
      firstInput.focus();
      return;
    });
  }

  async addNote(inputs: any) {
    const note: Note = {
      text: inputs.note,
      author: this.currentUser,
      date: Date.now(),
      status: PhotoCaptureStatus.Created
    };
    this.captureNote = note;
      this.capture.Notes = this.captureService.createNoteString(this.captureNote);
  }

  deleteNote() {
    this.captureNote = null;
    this.capture.Notes = null;
  }

  async completeSeries() {
    this.completeSeriesButton.nativeElement.disabled = true;
    this.openCameraButton.nativeElement.disabled = true;

    if (this.photoElement.photoCapturesCount > 0) {
      this.updateStoredJob(true)
      .then( ()=> {
        this.toastMessageService.showToastMessage('Job updated successfully.');
        this.photosPageService.completePhotoSeries$.next(this.photoElement);
        this.closeModal();
    })
      .catch( ()=> this.toastMessageService.showToastMessage('Failed to update the job.'));
    }
    else {
      //name of the photo to display to the user
      const reviewImageCaption = `${this.lexiconService.setNameFromSpecifierValues(this.photoElement.parentPhotoLogSectionSpecifierValues).toUpperCase()} - ${this.photoElement.name.toUpperCase()}`;

      const alert = await this.alertCtrl.create({
        header: 'Zero Capture Completion',
        subHeader: `'${reviewImageCaption}' has no captured images.`,
        message: 'To mark this item as complete, you must enter a reason:',
        cssClass: 'alert-main-container',
        inputs: [
          {
            name: 'input',
            type: 'text',
            cssClass: 'alert-input'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            cssClass: 'alert-cancel-button',
            handler: () => {
              this.completeSeriesButton.nativeElement.disabled = false;
              this.openCameraButton.nativeElement.disabled = false;
            }
          },
          {
            text: 'Complete',
            cssClass: 'alert-confirm-button',
            handler: (inputs: { input: string}) => {this.completePhotoSeries(inputs);}
          }
        ]
      });

      await alert.present().then(() => {
        //set focus on the input field
        const firstInput: any = document.querySelector('ion-alert input');
        firstInput.focus();
        return;
      });
      }
  }
  async completePhotoSeries(inputs: { input: string; }) {
    let xmlBlob: Blob;
    await this.fileService.createXMLBlob(this.currentUser, inputs.input, this.geoLocation, CaptureEventType.NoCapture)
      .then((blob: Blob) => xmlBlob = blob);

    // zero capture xml file name
    const filename: string = this.photoElement.photoCode + '_00_' + Date.now() + '.xml';

    // upload a zero capture xml file
    this.overlayService.showLoadingOverlay('Please wait...').then(async (completingSeriesOverlay) => {
      completingSeriesOverlay.present();
      completingSeriesOverlay.onDidDismiss().then(()=> this.closeModal());
      this.photosPageService.completePhotoSeries$.next(this.photoElement);

      await this.captureService.uploadFile(xmlBlob, filename).then(async () => {
        completingSeriesOverlay.dismiss();
        await this.updateStoredJob(true)
        .then( ()=> {
          this.toastMessageService.showToastMessage('Job updated successfully.');
        })
        .catch( ()=> {
          this.toastMessageService.showToastMessage('Failed to update the job.');
        });
      }
      ).
      catch((error: Error) => {
        completingSeriesOverlay.dismiss();
        this.toastMessageService.showToastMessage(`Failed to upload. Message ${error.message}`, 'danger');
        const xmlFile: BlobFile = {name: filename, data: xmlBlob};
        this.localStorageService.saveFailedUploadFileToFilesystem(xmlFile)
        .then( ()=> {
          this.toastMessageService.showToastMessage('Failed upload file saved to the device successfully.');
          this.completeSeriesButton.nativeElement.disabled = false;
          this.openCameraButton.nativeElement.disabled = false;
        })
        .catch( (error: Error)=> {
          this.toastMessageService.showToastMessage(`Failed upload file not saved. Message: ${error.message}`, 'danger');
          this.completeSeriesButton.nativeElement.disabled = false;
          this.openCameraButton.nativeElement.disabled = false;
        });
      });
    });
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  //sets the difference between the local time and the utc time in form of a string
  // where hours and minutes are separated by given string e.g. +0100 or +01:00
  private setTimeDifferenceStringFromDate(date: Date, separator: string = ''): string {
    let timeDifferenceString = '+';
    const hourDifference = date.getHours() - date.getUTCHours();
    const minuteDifference = date.getMinutes() - date.getUTCMinutes();

    if (hourDifference < 0) {
      timeDifferenceString = '-';
    }

    if (minuteDifference < 0) {
      timeDifferenceString = '-';
    }

    timeDifferenceString += this.padTo2Digits(Math.abs(hourDifference)) + separator + this.padTo2Digits(Math.abs(minuteDifference));
    return timeDifferenceString;
  }

  //set the number to string with 2 digits
  private padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

}
