<ion-header class="ontopMapLayer">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="toolbar-button" (click)="myBackButton()">
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ pageTitle }}</ion-title>
    <ion-icon size="large" [color]="isConnectedIconColor" slot="end" [name]="connectionIcon"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="content-container">
    <div class="item-container">
      <ion-item-group class="ontopMapLayer">
        <ion-item lines="none">
          <ion-label>
            <h2 class="secondary-text-header">JOB NAME</h2>
            <p class="secondary-text-value">{{ job.Name }}</p>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <h2 class="secondary-text-header">JOB CODE</h2>
            <p class="secondary-text-value">{{ job.Code }}</p>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="hasSite" lines="none">
          <ion-label>
            <h2 class="secondary-text-header">JOB SITE</h2>
            <p class="secondary-text-value">{{ job.Site.Address.Street1 }}</p>
            <p class="secondary-text-value">{{ job.Site.Address.Street2 }}</p>
            <p class="secondary-text-value">{{ job.Site.Address.City }}, {{ job.Site.Address.State }} {{ job.Site.Address.Zip }}</p>
            <p class="secondary-text-value">{{ job.Site.Address.CountryName }}</p>
          </ion-label>
        </ion-item>
      </ion-item-group>
    </div>

    <div class="map-container">
      <capacitor-google-map id="map" #map></capacitor-google-map>
    </div>
  </div>
</ion-content>