/* eslint-disable max-len */
import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertController, AlertInput, ModalController } from '@ionic/angular';
import { FullSizeCaptureElement } from 'src/app/shared/models/full-size-capture-element';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { FileSystem_JobFolders, Filesystem_JobFolder_PhotosFolders, SimpleStatus } from 'src/app/shared/lookups/enums';
import { BASE64_IMAGE_PREFIX, FILESYSTEM_JOBS_FOLDER, STORAGE_MY_LOCATION } from 'src/app/shared/lookups/consts';
import { LoadingOverlayService } from 'src/app/shared/services/loading-overlay.service';
import { CaptureService } from 'src/app/shared/services/capture.service';
import { Note } from 'src/app/shared/models/note';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { TakePhotoModalComponent } from '../take-photo-modal/take-photo-modal.component';
import { PhotoElement } from 'src/app/shared/models/photo-element';
import { PhotosPageService } from 'src/app/shared/services/photos-page.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { BlobFile } from 'src/app/shared/models/CustomFiles';
import { Camera, CameraSource, CameraResultType, Photo } from '@capacitor/camera';
import { SignalRHubService } from 'src/app/shared/services/signal-r-hub.service';
import { CaptureUpdate } from 'src/app/shared/models/capture-update';
import { APPGeoLocationService } from 'src/app/shared/services/app.location.service';
import { JobService } from 'src/app/shared/services/job.service';
import { SwiperOptions } from 'swiper/types';
import { Position } from '@capacitor/geolocation';


@Component({
  selector: 'app-photo-modal',
  templateUrl: './photo-modal.component.html',
  styleUrls: ['./photo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoModalComponent implements OnInit, OnDestroy {

  @ViewChild('swiper', ) swiper: ElementRef | undefined ;
  @Input() fullSizeCaptureElements: FullSizeCaptureElement[];
  @Input() photoElement: PhotoElement;

  isPhotoAccepted$ = new BehaviorSubject<boolean>(false);
  newThumbnailData: string;

  geoLocation: Position;

  swiperConfig: SwiperOptions = {
    pagination: false
  };  

  //keeps tract of the swiper index
  currentIndex = 1;

  // this is used to unsubscribe any/all subscriptions when the component is destroyed
  // to prevent memory leaks
  private componentDestroyed$ = new Subject<void>();

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private localStorageService: LocalStorageService,
    private overlayService: LoadingOverlayService,
    private captureService: CaptureService,
    private jobService: JobService,
    private signalRHubService: SignalRHubService,
    private toastMessageService: ToastMessageService,
    private photosPageService: PhotosPageService,
    private locationService: APPGeoLocationService,
    private zone: NgZone,
  ) {
  }
  
  ngOnInit() {
    this.signalRHubService.onUpdateReceived$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (captureUpdate: CaptureUpdate) => {
        const elementToUpdate = this.fullSizeCaptureElements.find(e => e.captureCode === captureUpdate.code);
    
        if (elementToUpdate) {
          this.photosPageService.updateFullSizeCaptureElementFromCaptureUpdate(captureUpdate, elementToUpdate);
        }
      }
    });
  
    this.photosPageService.updatedPhotoElement$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (pe) => {
        if (pe.photoCode === this.photoElement.photoCode) {
          this.isPhotoAccepted$.next(pe.currentStatus === SimpleStatus.Accepted);
        }
      }
    });

    this.localStorageService.getFromStorage(STORAGE_MY_LOCATION).then((location) => {
      this.geoLocation = location;
    });
    
    this.locationService.currentPosition$()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (position: Position) => {
        if (position) {
          this.geoLocation = position;          
        }
      }
    });
    
    // reset the new thumbnail data
    this.newThumbnailData = null;
    this.readFullSizeCapturesFromStorage()
    .catch((error) => {
      this.toastMessageService.showToastMessage('Something went wrong while reading the device storage.');
    });

    // disable the button once the capture for the photo is accepted
    this.isPhotoAccepted$.next(this.photoElement.currentStatus === SimpleStatus.Accepted);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();   
  }

  async openCamera(){
    if (await this.locationService.isLocationServiceOn()) {
      // get and the current user's latest position
      const latestPosition = await this.locationService.getCurrentUserLocation();    
      if (latestPosition) {
        this.geoLocation = latestPosition;
      }
    
      // check if we have valid most recent user's position
      if (!this.locationService.isLocationValid(this.geoLocation)) {
        this.toastMessageService.showToastMessage('Failed to get your recent position.', 'warning');
        return;
      }

      const takenPhoto = await Camera.getPhoto({
        source: CameraSource.Camera,
        allowEditing: false,
        quality: 70,
        resultType: CameraResultType.Base64
      }).catch((error: Error) => console.log(error.message));

      if (takenPhoto) {
        this.showTakePhotoModal(takenPhoto);
      }
    } else {
      this.toastMessageService.showToastMessage('Please turn on the location.', 'warning');
    }
  }

  async selectFromGallery(){
    if (await this.locationService.isLocationServiceOn()) {
      // get and the current user's latest position
      const latestPosition = await this.locationService.getCurrentUserLocation();    
      if (latestPosition) {
        this.geoLocation = latestPosition;
      }
    
      // check if we have valid most recent user's position
      if (!this.locationService.isLocationValid(this.geoLocation)) {
        this.toastMessageService.showToastMessage('Failed to get your recent position.', 'warning');
        return;
      }
      //display device photos
      const gallerySelectedPhoto = await Camera.getPhoto({
        source: CameraSource.Photos,
        allowEditing: false,
        quality: 70,
        resultType: CameraResultType.Base64
      }).catch((error: Error) => console.log(error.message));

      if (gallerySelectedPhoto) {
        this.showTakePhotoModal(gallerySelectedPhoto);
      }
    } else {
      this.toastMessageService.showToastMessage('Please turn on the location.', 'warning');
    }
  }

  async closeModal(){
    //when a new slide is added swiper needs to be updated.
    this.swiper.nativeElement.swiper.update();
    await this.modalCtrl.dismiss(this.newThumbnailData);
  }

  async presentAddNoteModal(){
    const currentElement: FullSizeCaptureElement = this.fullSizeCaptureElements[this.swiper.nativeElement.swiper.activeIndex];
    const alert = await this.alertCtrl.create({
      header: 'Add Note',
      cssClass: 'alert-main-container',
      inputs: [
        {
          name: 'note',
          type: 'text',
          cssClass: 'alert-input',
          placeholder: 'Enter Note'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-cancel-button'
        },
        {
          text: 'Add Note',
          cssClass: 'alert-confirm-button',
          handler: (inputs: AlertInput) => {this.addNote(inputs, currentElement);}
        }
      ]
    });

    await alert.present().then(() => {
      //set focus on the input field
      const firstInput: any = document.querySelector('ion-alert input');
      firstInput.focus();
      return;
    });
  }

  prevSlide(){
    this.swiper.nativeElement.swiper.slidePrev();
  }

  nextSlide(){
    this.swiper.nativeElement.swiper.slideNext();
  }

  nextIndex(){
    this.zone.run(async () => {
      this.currentIndex = this.currentIndex + 1;
    });
  }

  prevIndex(){
    this.zone.run(async () => {
      this.currentIndex = this.currentIndex - 1;
    });
  }

  activeIndexChange(event: any){
    this.zone.run(async () => {
      //full size element after the transition has been complete
      const element = this.fullSizeCaptureElements[event.detail[0].activeIndex];
      if (!element.isFullSizeDownloaded) {
        await this.overlayService.showLoadingOverlay('Downloading full size capture...').then(overlay => {
          overlay.present().then(async () => {
            this.captureService.downloadCaptureAsync(Filesystem_JobFolder_PhotosFolders.LoRes, element.jobFolderCode, element.fileName)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
              next: (file: BlobFile) => {
                const filePath = `${FILESYSTEM_JOBS_FOLDER}/${element.jobFolderCode}/${FileSystem_JobFolders.Photos}/${Filesystem_JobFolder_PhotosFolders.LoRes}/${file.name}`;
                this.localStorageService.saveBlobToFilesystem(file.data, filePath)
                .then( () => {
                  this.localStorageService.readImageFileData(Filesystem_JobFolder_PhotosFolders.LoRes, element.jobFolderCode, element.fileName)
                  .then(f => {
                    element.fullSizeCaptureData = BASE64_IMAGE_PREFIX + f.data;
                    element.isFullSizeDownloaded = true;
                    this.overlayService.dismissLoadingOverlay();
                  });
                })
                .catch((error: Error) => {
                  this.toastMessageService.showToastMessage('Something went wrong when saving the image.', 'danger');
                });
              },
              error: (error: Error) =>{
                this.toastMessageService.showToastMessage('Something went wrong when downloading the image.');
                overlay.dismiss();
              }
            });
          });
        });
      }
    });
  }

  private async addNote(inputs: any, element: FullSizeCaptureElement){
    const note: Note = {
      text: inputs.note,
      author: 'appuser', //TODO: needs a discussion what should be used as an author eg. users name, phone model etc.
      date: Date.now(),
    };

    //add the note to the current element
    await this.jobService.saveNote(note, element.captureCode).then(()=> {
      element.notes.unshift(note);
    }).catch((error: Error) => {
      console.log(error.message);
      this.toastMessageService.showToastMessage(error.message);
    });
  }

  private async showTakePhotoModal(photo: Photo = null){
   //subscribe to new full size element event emitter
   const eventEmitter= new EventEmitter<FullSizeCaptureElement>();
   eventEmitter
   .pipe(takeUntil(this.componentDestroyed$))
   .subscribe({
    next: (newFullSizeElement: FullSizeCaptureElement) => {
      this.zone.run(() =>{
        console.log('newFullSizeElement', newFullSizeElement);
        
        this.addNewFullSizeElement(newFullSizeElement);
      });
    }
  });

   //show take photo modal
   await this.modalCtrl.create({
     component: TakePhotoModalComponent,
     componentProps: {
       photoElement: this.photoElement,
       inputPhoto: photo,
       newFullSizeElement: eventEmitter
     }
   }).then(modal =>  {
     modal.onDidDismiss().then((imageData) => {
       if (imageData.data) {
        this.newThumbnailData = imageData.data;
       }
     });

     modal.present();
   });
  }

  private addNewFullSizeElement(newFullSizeElement: FullSizeCaptureElement) {
    this.fullSizeCaptureElements.unshift(newFullSizeElement);
    this.swiper.nativeElement.swiper.update();
  }

  private async readFullSizeCapturesFromStorage(): Promise<void> {
    const promises: Promise<void>[] = [];

    this.fullSizeCaptureElements.forEach((fse) => {
      if (fse.isFullSizeDownloaded) {
        const promise = this.localStorageService.readImageFileData(Filesystem_JobFolder_PhotosFolders.LoRes, fse.jobFolderCode, fse.fileName)
          .then((file) => {
            fse.fullSizeCaptureData = BASE64_IMAGE_PREFIX + file.data;
          });

        promises.push(promise);
      }
    });

    await Promise.all(promises);
  }
}
