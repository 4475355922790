import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { ConnectedIconColor } from '../lookups/enums';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  isConnectedIconColor$ = new BehaviorSubject<string>(ConnectedIconColor.Success);

  private isConnected$ = new BehaviorSubject<boolean>(true);

  constructor(
    private platform: Platform,
    private zone: NgZone // so angular can detect changes made by 'external' plugins(Capacitor Network etc)
  ) {
    this.listenToNetworkChanges();
  }

  private listenToNetworkChanges(): void {
    if (this.platform.is('hybrid')) {
      // Capacitor
      Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
        this.zone.run(() => {
          this.isConnected$.next(status.connected);
        });
      });
    } else {
      // Web browsers
      fromEvent(window, 'online').subscribe(() => {
        this.zone.run(() => {
          this.isConnected$.next(true);
        });
      });
      fromEvent(window, 'offline').subscribe(() => {
        this.zone.run(() => {
          this.isConnected$.next(false);
        });
      });
    }
  }

  public getIsNetworkConnectedStatus$(): Observable<boolean> {
    return this.isConnected$.asObservable();
  }
}
