/* eslint-disable @typescript-eslint/naming-convention */
export enum JobStatus {
  Test = -1000,
  Canceled = -3,
  Suspended = -2,
  Deleted = -1,
  Created = 0,
  Ready = 1,
  Started = 2,
  Paused = 3,
  Completed = 4,
  Accepted = 5,
  Rejected = 6,
  Closed = 7,
}

export enum PhotoStatus {
  Uninitialized = 0x8000,
  Default = 0,
  Completed = 0x0001,
  IsSeries = 0x0002,
  HasBarcode = 0x0004,
  IsRequired = 0x0008,
  Accepted = 0x0010,
  Flagged = 0x0020,
  Rejected = 0x0040,
  HasChildren = 0x0080,
  IsPriority = 0x0100,
}

export enum PhotoCaptureStatus {
  NoChange = -10000, // pseudo-status for function calls
  Renewing = -1000, // pseudo-status for resetting
  Deleted = -4,
  Recoded = -3,
  Rejected = -2,
  Flagged = -1,
  Created = 0,
  Accepted = 1,
  NotApplicable = 2,
}

export enum SimpleStatus {
  Submitted,
  Rejected,
  Awaiting,
  Accepted,
  Flagged,
  Attention
}

export enum SettingNames {
  siteUrl,
}

export enum FileSystem_JobFolders {
  Documents = 'Documents',
  Export = 'Export',
  Inbox = 'Inbox',
  Outbox = 'Outbox',
  Photos = 'Photos',
  Templates = 'Templates',
}

export enum CaptureEventType {
    // eslint-disable-next-line id-blacklist
    Undefined = 'Undefined',
    ImageCapture = 'ImageCapture',
    NoCapture = 'NoCapture',
    DataCapture = 'DataCapture',
    UserUpload = 'UserUpload',
    AmendCapture = 'AmendCapture',
};

export enum Filesystem_JobFolder_PhotosFolders {
  LoRes = 'LoRes',
  Metadata = 'Metadata',
  //thumbnail
  TileLg = 'TileLg',
  //higher resolution thumbnail
  TileXl = 'TileXl',
  VGA = 'VGA',
  Workarea = 'Workarea',
}

export enum Ion_ProgressBar_Type {
  Determinate = 'determinate',
  Indeterminate = 'indeterminate',
}

export enum StatusIcon {
  Submitted = 'ellipse-sharp',
  Awaiting = 'time-sharp',
  Attention = 'alert-sharp',
  Accepted = 'checkmark-sharp',
  Flagged = 'flag-sharp',
  Rejected = 'close-sharp',
  IsSeries = 'images',
  IsPriority = 'alert-circle-sharp',
  Camera = 'camera-sharp'
};

export enum StatusIconColor {
  Submitted = 'secondary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Transparent = 'transparent',
}

export enum ConnectedIconColor {
  Medium = 'medium',
  Success = 'success',
  Danger = 'danger'
}

export enum PhotoFiltersText {
  Submitted = 'Submitted',
  ToDo = 'To Do',
  Attention = 'Attention',
  Accepted = 'Accepted',
  Flagged = 'Flagged',
  Rejected = 'Rejected'
}

export enum BlobFileType {
  Image = 'image/jpeg',
  XML = 'text/xml'
}
