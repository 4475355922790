/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@capacitor/google-maps';
import { Marker } from '@capacitor/google-maps/dist/typings/definitions';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';
import { NamedColor } from 'src/app/shared/lookups/types';
import { Coordinates } from 'src/app/shared/models/coordinates';
import { Job } from 'src/app/shared/models/job';
import { JobService } from 'src/app/shared/services/job.service';
import { NetworkService } from 'src/app/shared/services/network.service';
import { SignalRHubService } from 'src/app/shared/services/signal-r-hub.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-details-overview',
  templateUrl: './job-details-overview.page.html',
  styleUrls: ['./job-details-overview.page.scss'],
})
export class JobDetailsOverviewPage implements OnInit, OnDestroy {

  @ViewChild('map') mapRef: ElementRef;
  map: GoogleMap;
  pageTitle = 'Overview';
  
  
  // network/signalR status related properties
  isConnectedIconColor: NamedColor = 'light';
  connectionIcon: string = 'wifi-outline';
  isNetworkAvailable: boolean = true;
  isSignalRConnected: boolean = true;

  // this is used to unsubscribe any/all subscriptions when the component is destroyed
  // to prevent memory leaks
  private componentDestroyed$ = new Subject<void>();

  //keeps track of current map markers, so we can delete them when we go to next job
  mapCurrentMarkers: string[];
  mapAPIKey: string;
  //London coordinates
  defaultCoordinates: Coordinates = { Latitude: 51.509865, Longitude: -0.118092 };

  job: Job;
  public hasSite = false;
  lat = this.defaultCoordinates.Latitude;
  lng = this.defaultCoordinates.Longitude;


  //toast message pop up instance
  private toast;

  constructor(
    private jobService: JobService,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private platform: Platform,
    private signalRHubService: SignalRHubService,
    private networkService: NetworkService,
  ) {
    this.job = this.jobService.currentViewedJob;
    this.platform.ready().then(() => {
     if (this.platform.is("android")) {
       this.mapAPIKey = environment.googleAdroidMapsAPIKey;

     }else if (this.platform.is("ios")) {
       this.mapAPIKey = environment.googleIOSMapsAPIKey;
     }
     else {
       this.mapAPIKey = environment.googleMapsAPIKey
     }
    })
  }

  ngOnInit() {
    this.networkService.getIsNetworkConnectedStatus$()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((isConnected: boolean) => {
      this.isNetworkAvailable = isConnected
      this.updateIcon()
    });
    
    this.signalRHubService.isSignalRConnected$()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (isSignalRConnected) => {
        this.isSignalRConnected = isSignalRConnected;
        this.updateIcon();
      }
    });
    this.hasSite = this.job.Site.Address != null;
    this.lat = this.job.Site.Address ? this.job.Site.Address.Latitude || this.defaultCoordinates.Latitude : this.defaultCoordinates.Latitude;
    this.lng=this.job.Site.Address ? this.job.Site.Address.Longitude || this.defaultCoordinates.Longitude : this.defaultCoordinates.Longitude;
   
    setTimeout(async () => {

     this.hasSite = this.job.Site.Address != null;
     this.createMap();
    }, 250);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();

    //remove markers when leaving page so they are not displayed on the next job
    this.map?.removeMarkers(this.mapCurrentMarkers);

    //remove all map listeners so we do not have multiple listeners for one event
    this.map?.removeAllMapListeners();

    //remove toast message overlay
    if (this.toast) {
      this.toastCtrl.dismiss();
    }
    this.map?.destroy();
  }

  myBackButton() {
    this.navCtrl.navigateBack('/jobs');
  }

  
  private updateIcon() {
    if (this.isSignalRConnected && this.isNetworkAvailable) {
      this.connectionIcon = 'wifi-outline'; // Example icon for no SignalR connection
      this.isConnectedIconColor = 'success';
    } else {
      this.connectionIcon = 'wifi-outline';
      this.isConnectedIconColor = 'danger';
    }
  }

  private async createMap() {
       
    this.map = await GoogleMap.create({
            
      id: 'my-map',
      apiKey: this.mapAPIKey,//environment.googleMapsAPIKey,
      element: this.mapRef.nativeElement,
     // forceCreate: true,
      config: {
        center: {
          lat: this.job.Site.Address ? this.job.Site.Address.Latitude || this.defaultCoordinates.Latitude : this.defaultCoordinates.Latitude,
          lng: this.job.Site.Address ? this.job.Site.Address.Longitude || this.defaultCoordinates.Longitude : this.defaultCoordinates.Longitude,
        },
        zoom: 10,
        height:(this.platform.height()-260),
        x:(this.platform.height()-260),        
      }
    });

    this.addMarkers();
  }

  private async addMarkers() {
    const markers: Marker[] = [];

    if (this.job.Site.Address && (this.job.Site.Address.Latitude && this.job.Site.Address.Longitude)) {
      markers.push({
        coordinate: {
          lat: this.job.Site.Address.Latitude,
          lng: this.job.Site.Address.Longitude
        },
        title: 'Site Location',
        snippet: this.job.Name
      });
    }
    else {
      await this.toastCtrl.create({
        message: 'Job Location coordinates are not valid.',
        duration: 3000,
        color: 'dark'
      }).then(toast => {
        this.toast = toast.present();
      });
    }

    //adds markers to the map
    this.mapCurrentMarkers = await this.map.addMarkers(markers);

    await this.map.setOnMarkerClickListener();
  }

  private async mapKey() {
    this.lat = this.job.Site.Address ? this.job.Site.Address.Latitude || this.defaultCoordinates.Latitude : this.defaultCoordinates.Latitude;
    this.lng = this.job.Site.Address ? this.job.Site.Address.Longitude || this.defaultCoordinates.Longitude : this.defaultCoordinates.Longitude;
  }
}
