
<ion-header>

  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button size="small" class="close-modal-button toolbar-button" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Capture Viewer</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>

  <swiper-container #swiper (swiperslidenexttransitionstart)="nextIndex()" (swiperslideprevtransitionstart)="prevIndex()" (swiperactiveindexchange)="activeIndexChange($event)" [config]="swiperConfig">

    <swiper-slide *ngFor="let fullSizeElement of fullSizeCaptureElements" swiperSlide>
      <ion-list>

        <ion-item-group style="display: block;">
          <ion-item>
            <ion-label>{{ fullSizeElement.capturedDate ?  (fullSizeElement.capturedDate | date: 'medium') : ' Capture has no date.'}}</ion-label>
            <ion-text class="capture-count-text" text-right>{{currentIndex}}/{{ photoElement.photoCapturesCount }}</ion-text>
          </ion-item>

          <ion-item lines="none">
            <ion-label>{{ photoElement.name}}</ion-label>
            <ion-icon slot="end" class="status-icon" [color]="fullSizeElement.captureStatusColor" [name]="fullSizeElement.captureStatusIcon"></ion-icon>
          </ion-item>

          <ion-img [src]="fullSizeElement.fullSizeCaptureData"></ion-img>

        </ion-item-group>

        <div style="height: 100%; overflow-y: scroll; display: block;">
          <ion-list>
            <ion-item>
              <ion-label>
                <p class="header-text">CAPTURE NOTES </p>
              </ion-label>
            </ion-item>


            <ion-item *ngFor="let note of fullSizeElement.notes" lines="none">
              <ion-label>

                <ion-label text-wrap> {{ note.text }} </ion-label>
                <h6 text-wrap><span class="small-header-text">AUTHOR:</span> {{ note.author }}</h6>
                <!-- <h6 text-wrap><span class="small-header-text">DATE:</span>{{ fullSizeElement.capturedDate | date: 'M/d/y h:mm a' }}</h6>  -->
                <h6 text-wrap><span class="small-header-text">DATE:</span>{{ note.date | date: 'M/d/y h:mm a' }}</h6>
              </ion-label>
            </ion-item>

            <ion-item *ngIf="!fullSizeElement.notes || fullSizeElement.notes.length === 0" lines="none">
              <ion-label text-wrap>
                <h2>Capture has no notes added yet.</h2>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>

      </ion-list>

    </swiper-slide>
  </swiper-container>

</ion-content>

<ion-footer>
  <ion-row>
    <ion-col size="3">
      <ion-button (click)="prevSlide()">
        <ion-icon slot="icon-only" name="chevron-back-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-row>

        <ion-button style="width:30%" (click)="presentAddNoteModal()">
          <ion-icon slot="icon-only" name="chatbox-outline"></ion-icon>
        </ion-button>


        <ion-button style="width:30%" [disabled]="isPhotoAccepted$ | async" (click)="openCamera()">
          <ion-icon slot="icon-only" name="camera-outline"></ion-icon>
        </ion-button>

        <ion-button style="width:30%" [disabled]="isPhotoAccepted$ | async" (click)="selectFromGallery()">
          <ion-icon slot="icon-only" name="folder-outline"></ion-icon>
        </ion-button>

      </ion-row>

    </ion-col>

    <ion-col size="3">
      <ion-button (click)="nextSlide()">
        <ion-icon slot="icon-only" name="chevron-forward-circle-outline"></ion-icon>
      </ion-button>
    </ion-col>

  </ion-row>
</ion-footer>
