<ion-grid>
  <ion-row>

    <ion-col size="4" *ngFor="let filter of filters">

      <div lines="none" (click)="applyFilter(filter)" class="filter-element" [class.filterApplied]="filter.isApplied">
        <div class="status-header">
          <ion-label>
            {{ filter.itemCount }}
          </ion-label>

          <ion-icon slot="end" class="status-icon" [color]="filter.color" [name]="filter.icon"></ion-icon>
        </div>
        <div class="status-text">
          <ion-label>
            <p>{{ filter.displayText }}</p>
          </ion-label>
        </div>
      </div>
    </ion-col>

  </ion-row>
</ion-grid>
